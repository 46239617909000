const axios = require("axios");

export default class AuthApi {
  constructor(tenantId, token, language) {
    this.client = axios.create({
      baseURL: `${process.env.VUE_APP_TRAINPLANET_API_URL_BASE}/account`,
    });
    this.client.interceptors.request.use((request) => {
      request.headers["Accept-Language"] = language ? language : "en-SE";
      request.headers["tenantId"] = tenantId;
      request.headers["Content-Type"] = "application/json";
      request.headers["Authorization"] = "Bearer " + token;
      return request;
    });
  }

  register(data) {
    return this.client.post(`/create`, data);
  }
  verifyAdmin(accountId) {
    return this.client.post(`${accountId}/verify`);
  }

  verify(verifyId) {
    return this.client.post(`/verify/${verifyId}`).then((response) => {
      return response;
    });
  }

  resendVerifyLink(data) {
    return this.client.post(`/sendVerificationLink`, data);
  }

  resetPasswordLink(data) {
    return this.client.post(`/sendPasswordResetLink`, data);
  }

  deleteAccount(accountId) {
    return this.client.delete(`/${accountId}`);
  }

  resetPassword(data, token) {
    return this.client.post(`/resetPassword/${token}`, data).then((response) => {
      return response;
    });
  }

  async accountList(filter = {}) {
    const { data, headers } = await this.client.get("/list", {
      params: {
        ...filter,
      },
    });

    return {
      meta: data.meta,
      data: data.accounts,
    };
  }

  getAccount(id) {
    return this.client.get(`/${id}`).then((response) => {
      return response;
    });
  }

  auth(data) {
    return this.client.post(`/login`, data).then((response) => {
      return response;
    });
  }
}
