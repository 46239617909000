<template>
  <div>
       <div v-if="item" style="max-width: 220px">
          <div class="grey--text text--darken-4 text-truncate">
             <v-icon
                  color="mango-red "
                  @click="copyToClipboard(item)"
                  small
                >
                  mdi-content-copy
                </v-icon>
            <span class="ml-1 font-weight-medium">{{ item }}</span>
          </div>
        </div>
        <div v-else class="grey--text text--darken-1">N/A</div>
  </div>
</template>

<script>
import {copyToClipboard} from "@/util/helpers";
import common from "@/mixins/common";

export default {
  name: "copyToClipboardField",
  methods: {copyToClipboard},
  props: ['item'],
  mixins: [common]
}
</script>

<style scoped>

</style>
