<template>
  <div>
    <v-alert :type="type ? type : 'info'"   dense :color="color ? color : 'third'" class="my-4" >
      <b>{{ textBold }}</b
      > {{ text }}
    </v-alert>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    textBold: String,
    color: String,
    type: String,    // success - info - warning - error
  },
};
</script>
<style lang="scss" scoped>
</style>
