<template>
  <div>
    <div
      v-if="ifCondition && !(isLower && isUpper && isSpaceless && isNumber && this.password.length >= 8 && isConfirm)"
      class="password-box border border-slate-200 rounded-lg p-4 flex flex-col items-start w-100 flex mb-3 mt-2"
      style="
        width: 100%;
        position: absolute;
        top: 70px;
        left: 10px;
        background-color: white;
        width: 300px;
        z-index: 99;
      ">
      <div class="gap-2 item">
        <img src="../../assets/accept.png" v-if="isLower" alt="" />
        <img src="../../assets/cancel.png" v-if="!isLower" alt="" />
        Must contain at least 1 lowercase letter
      </div>
      <div class="flex gap-2 item">
        <img src="../../assets/accept.png" v-if="isUpper" alt="" />
        <img src="../../assets/cancel.png" v-if="!isUpper" alt="" />
        Must contain at least 1 uppercase letter
      </div>

      <div class="flex gap-2 item">
        <img src="../../assets/accept.png" v-if="isSpaceless" alt="" />
        <img src="../../assets/cancel.png" v-if="!isSpaceless" alt="" />
        Must not contain any whitespaces
      </div>

      <div class="flex gap-2 item">
        <img src="../../assets/accept.png" v-if="isNumber" alt="" />
        <img src="../../assets/cancel.png" v-if="!isNumber" alt="" />
        Must contain at least 1 number
      </div>

      <div v-if="this.password" class="flex gap-2 item">
        <img src="../../assets/accept.png" v-if="this.password.length >= 8" alt="" />
        <img src="../../assets/cancel.png" v-if="this.password.length < 8" alt="" />
        Minimum 8 Characters
      </div>

      <div class="flex gap-2 item">
        <img src="../../assets/accept.png" v-if="isConfirm" alt="" />
        <img src="../../assets/cancel.png" v-if="!isConfirm" alt="" />
        Two passwords have to be a same
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "passwordControl",
  props: {
    ifCondition: false,
    password: "",
    passwordConfirm: "",
  },
  emits: ["done"],
  data() {
    return {
      isLower: false,
      isUpper: false,
      isSpaceless: false,
      isNumber: false,
      isConfirm: false,
    };
  },
  created() {
    this.passwordChange();
  },
  watch: {
    password: {
      handler: function f(val) {
        this.passwordChange();
      },
    },

    passwordConfirm: {
      handler: function f(val) {
        this.passwordChange();
      },
    },
  },
  methods: {
    passwordChange() {
      if (this.password) {
        let upperCase = /[A-Z]/;
        let lowerCase = /[a-z]/;
        let number = /[0-9]/;
        let whitespace = /^\S+$/;
        this.isSpaceless = whitespace.test(this.password);
        this.isLower = lowerCase.test(this.password);
        this.isUpper = upperCase.test(this.password);
        this.isNumber = number.test(this.password);
        this.isConfirm = !!(this.password == this.passwordConfirm);
      } else {
        this.isLower = false;
        this.isUpper = false;
        this.isSpaceless = false;
        this.isNumber = false;
        this.isConfirm = false;
      }
      if (
        (this.isConfirm &&
          this.isLower &&
          this.isNumber &&
          this.isConfirm &&
          this.isUpper &&
          this.isSpaceless &&
          this.password &&
          this.passwordConfirm) ||
        (!this.password && !this.passwordConfirm)
      ) {
        this.$emit("done", true);
      } else {
        this.$emit("done", false);
      }
    },
  },
};
</script>
