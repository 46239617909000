var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.item ? _c('div', {
    staticStyle: {
      "max-width": "220px"
    }
  }, [_c('div', {
    staticClass: "grey--text text--darken-4 text-truncate"
  }, [_c('v-icon', {
    attrs: {
      "color": "mango-red ",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.copyToClipboard(_vm.item);
      }
    }
  }, [_vm._v(" mdi-content-copy ")]), _c('span', {
    staticClass: "ml-1 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.item))])], 1)]) : _c('div', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v("N/A")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }