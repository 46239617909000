var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-alert', {
    staticClass: "my-4",
    attrs: {
      "type": _vm.type ? _vm.type : 'info',
      "dense": "",
      "color": _vm.color ? _vm.color : 'third'
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.textBold))]), _vm._v(" " + _vm._s(_vm.text) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }