var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isAll ? _c('v-select', {
    attrs: {
      "label": "Select Tenant",
      "outlined": "",
      "items": _vm.tempTenant,
      "item-text": "name",
      "item-value": "id"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-avatar', {
          attrs: {
            "width": "32",
            "height": "32",
            "tile": ""
          }
        }, [_c('img', {
          staticStyle: {
            "margin": "5px"
          },
          attrs: {
            "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(item.name) + '/logos/favicon-32x32.png'
          }
        })]), _vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-avatar', {
          attrs: {
            "width": "32",
            "height": "32",
            "tile": ""
          }
        }, [_c('img', {
          staticStyle: {
            "margin": "5px"
          },
          attrs: {
            "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(item.name) + '/logos/favicon-32x32.png'
          }
        })]), _vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }], null, false, 2575987712)
        }, [_c('span', [_vm._v("Choose tenant to create the right theme.")])])];
      },
      proxy: true
    }], null, false, 3270309493),
    model: {
      value: _vm.selectedTenant,
      callback: function ($$v) {
        _vm.selectedTenant = $$v;
      },
      expression: "selectedTenant"
    }
  }) : _vm._e(), _vm.isAll ? _c('v-select', {
    attrs: {
      "label": "Select Tenant",
      "outlined": "",
      "items": _vm.allTenantsMap,
      "item-text": "name",
      "item-value": "id"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-avatar', {
          attrs: {
            "width": "32",
            "height": "32",
            "tile": ""
          }
        }, [_c('img', {
          staticStyle: {
            "margin": "5px"
          },
          attrs: {
            "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(item.name) + '/logos/favicon-32x32.png'
          }
        })]), _vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-avatar', {
          attrs: {
            "width": "32",
            "height": "32",
            "tile": ""
          }
        }, [_c('img', {
          staticStyle: {
            "margin": "5px"
          },
          attrs: {
            "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(item.name) + '/logos/favicon-32x32.png'
          }
        })]), _vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }], null, false, 2575987712)
        }, [_c('span', [_vm._v("Choose tenant to create the right theme.")])])];
      },
      proxy: true
    }], null, false, 3270309493),
    model: {
      value: _vm.selectedTenant,
      callback: function ($$v) {
        _vm.selectedTenant = $$v;
      },
      expression: "selectedTenant"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }