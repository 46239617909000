<template>
  <div class="">
    <div class="py-8 bg-white rounded-lg bx-shadow mt-3">
      <div
        v-show="step === 1"
        class="flex justify-center flex-col items-center register min-500"
        style="min-width: 200px">
        <h2 class="text-black text-center font-medium text-2xl" style="margin-bottom: 3rem">Create Account</h2>
        <v-form
          @submit.prevent="register"
          ref="registerForm"
          class="grid grid-cols-1 flex text-start flex-wrap flex-row gap-4 w-100 justify-center items-center register-form"
          style="width: 100%">
          <v-row>
            <v-col cols="12" lg="3" md="6" sm="6" xs="12" class="pb-0">
              <v-text-field
                :rules="[rules.required]"
                v-model="form.firstName"
                outlined
                clearable
                class="font-weight-bold"
                label="First Name"
                hide-details
                color="orange"></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12" class="pb-0">
              <v-text-field
                v-model="form.lastName"
                outlined
                clearable
                class="font-weight-bold"
                label="Last Name"
                hide-details
                color="orange"></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12" class="pb-0">
              <v-text-field
                :rules="[rules.required]"
                v-model="form.email"
                outlined
                clearable
                class="font-weight-bold"
                label="Email"
                color="orange"></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="12" xs="12" class="pb-0">
              <VuePhoneNumberInput
                :default-country-code="selectedCountryCode"
                @update="logPhone"
                :error="!phoneValid"
                v-model="form.phone"
                :clearable="true"
                style="height: 60px !important"
                :countries-height="50" />
            </v-col>

            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="pb-0">
              <v-btn color="primary" style="height: 56px; width: 100%" @click="uuidv4">Generate Password</v-btn>
            </v-col>

            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="pb-0" style="position: relative">
              <v-text-field
                :rules="[rules.required]"
                v-model="form.password"
                :append-icon="passEye ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
                clearable
                class="font-weight-bold"
                label="Password"
                :type="passEye ? 'text' : 'password'"
                @click:append="passEye = !passEye"
                hide-details
                color="orange"></v-text-field>

              <password-control
                v-on:done="isDone = $event"
                :ifCondition="this.form.password && this.form.password != ''"
                :password="this.form.password"
                :passwordConfirm="passwordConfirm" />
            </v-col>

            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="pb-0">
              <v-text-field
                :rules="[rules.required]"
                v-model="passwordConfirm"
                @click:append="passEye = !passEye"
                hide-details
                :type="passEye ? 'text' : 'password'"
                :append-icon="passEye ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
                clearable
                class="font-weight-bold"
                label="Password Confirm"
                color="orange"></v-text-field>
            </v-col>

            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="pb-0"> </v-col>
            <v-col cols="12" class="pb-0"> </v-col>

            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="pb-0"> </v-col>

            <v-col class="register-tenant" cols="12" lg="4" md="6" sm="6" xs="12">
              <SelectTenant
                :isAll="true"
                :tenants="allTenants"
                :allTenants="listedTenants"
                v-on:onSelect="selectTenant($event)" />

              <!-- <v-select solo v-model="tenantId" :items="allTenants" append-outer-icon="mdi-map" menu-props="auto"
                hide-details label="Select" single-line></v-select> -->
            </v-col>

            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="pb-0" style="position: relative"> </v-col>
            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="pb-0" style="position: relative"> </v-col>

            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="6"
              xs="12"
              class="pb-0"
              style="height: 40px; justify-content: center; display: flex">
              <v-btn @click="register" dark small color="accent" style="height: 56px; width: 100%; font-size: 14px">
                <span v-show="!loading"> Create Account </span>
                <div v-show="loading" class="mng-loader"></div>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </div>
</template>
<style>
.password-box img {
  width: 20px;
  height: 20px;
}

.password-box .item {
  align-items: center;
  margin-top: 1rem;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.register-tenant .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}

.password-box {
  border: 1px solid rgba(0, 0, 0, 0.589);
}

@media (min-width: 900px) {
  .register {
    padding: 0 100px;
  }

  .register-form {
    width: 80%;
  }
}

.input-tel,
.country-selector {
  height: 100% !important;
}

.input-tel__input,
.country-selector__input {
  height: 100% !important;
}

.password-box {
  padding: 1rem 1rem;
}
</style>
<script>
import passwordControl from "@/components/common/PasswordController";
import router from "@/router";
import store from "@/store";
import CountryList from "country-list-with-dial-code-and-flag";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapGetters } from "vuex";
import AuthApi from "../../../services/auth.api";
import SelectTenant from "../../../util/shared-components/select-tenant.vue";

export default {
  name: "AccountRegister",
  props: {
    isPopUp: false,
  },
  emits: ["registerDone"],
  data() {
    return {
      selectedCountryCode: "SE",
      phoneValid: true,
      rules: {
        required: (value) => !!value || "Required.",
      },
      form: {
        lastName: "",
        firstName: "",
        phone: "",
        email: "",
        password: "",
      },
      sharedItems: passwordControl.data,
      passwordConfirm: "",
      passEye: false,
      hasError: 0,
      loading: false,
      allTenants: [],
      successMsg: false,

      isDone: false,
      step: 1,
      tenantId: "",
      token: "",
      listedTenants: [],
    };
  },

  ready: function () {
    passwordChange();
  },
  components: {
    SelectTenant,
    passwordControl,
    VuePhoneNumberInput,
  },
  created() {
    this.getTenant();
    this.getToken();
  },
  watch: {
    allTenantsMap: {
      handler: function (value) {
        this.listedTenants = this.allTenantsMap;
      },
    },
  },
  computed: {
    ...mapGetters({
      allTenantsMap: "auth/userTenants",
      userRoles: "auth/userRoles",
      user: "auth/user",
    }),
    AuthModel() {
      return new AuthApi(this.tenantId, this.token);
    },
  },
  methods: {
    CountryList,
    logPhone(payload) {
      this.selectedCountryCode = payload.countryCode;
      if (this.form.phone) {
        this.phoneValid = payload.isValid;
      } else {
        this.phoneValid = true;
      }
    },
    uuidv4() {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 12) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      this.form.password = result;
      this.passwordConfirm = result;
      var number = /[0-9]/;
      var isNumber = number.test(result);
      if (isNumber) {
        this.passEye = true;
      } else {
        this.uuidv4();
      }
    },
    selectTenant(event) {
      if (typeof event == "object") {
        this.tenantId = event.id;
      } else {
        this.tenantId = event;
      }
    },
    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
      this.allTenants = res.tenants;
    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
    },
    goAuth() {
      this.$router.push({ name: "Login" }); // redirect
    },

    register() {
      this.hasError = this.successMsg = false;
      if (this.$refs.registerForm.validate() && this.phoneValid) {
        if (!this.isDone) {
          this.loading = false;
          this.$store.dispatch("error", "Password is not correct type");
        } else {
          this.loading = true;
          var phoneString = "";
          if (this.form.phone) {
            var dialCode = window.CountryList.findOneByCountryCode(this.selectedCountryCode).data.dial_code;
            phoneString = dialCode + " " + this.form.phone.replace(/\s/g, "");
          }
          this.AuthModel.register({
            phone: phoneString ? phoneString : null,
            email: this.form.email,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            password: this.form.password,
          })
            .then(({ data }) => {
              this.$store.dispatch("success", "Account registered succesfuly");
              if (!this.isPopUp) {
                this.$router.push({
                  name: "account-detail",
                  params: {
                    id: data.id,
                  },
                });
              } else {
                this.$emit("registerDone");
              }
            })
            .catch((error) => {
              this.hasError = error.response.data.status;
              this.$store.dispatch("error", error.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
  mounted() {},
};
</script>
