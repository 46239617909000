var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.ifCondition && !(_vm.isLower && _vm.isUpper && _vm.isSpaceless && _vm.isNumber && this.password.length >= 8 && _vm.isConfirm) ? _c('div', {
    staticClass: "password-box border border-slate-200 rounded-lg p-4 flex flex-col items-start w-100 flex mb-3 mt-2",
    staticStyle: {
      "width": "300px",
      "position": "absolute",
      "top": "70px",
      "left": "10px",
      "background-color": "white",
      "z-index": "99"
    }
  }, [_c('div', {
    staticClass: "gap-2 item"
  }, [_vm.isLower ? _c('img', {
    attrs: {
      "src": require("../../assets/accept.png"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.isLower ? _c('img', {
    attrs: {
      "src": require("../../assets/cancel.png"),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" Must contain at least 1 lowercase letter ")]), _c('div', {
    staticClass: "flex gap-2 item"
  }, [_vm.isUpper ? _c('img', {
    attrs: {
      "src": require("../../assets/accept.png"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.isUpper ? _c('img', {
    attrs: {
      "src": require("../../assets/cancel.png"),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" Must contain at least 1 uppercase letter ")]), _c('div', {
    staticClass: "flex gap-2 item"
  }, [_vm.isSpaceless ? _c('img', {
    attrs: {
      "src": require("../../assets/accept.png"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.isSpaceless ? _c('img', {
    attrs: {
      "src": require("../../assets/cancel.png"),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" Must not contain any whitespaces ")]), _c('div', {
    staticClass: "flex gap-2 item"
  }, [_vm.isNumber ? _c('img', {
    attrs: {
      "src": require("../../assets/accept.png"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.isNumber ? _c('img', {
    attrs: {
      "src": require("../../assets/cancel.png"),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" Must contain at least 1 number ")]), this.password ? _c('div', {
    staticClass: "flex gap-2 item"
  }, [this.password.length >= 8 ? _c('img', {
    attrs: {
      "src": require("../../assets/accept.png"),
      "alt": ""
    }
  }) : _vm._e(), this.password.length < 8 ? _c('img', {
    attrs: {
      "src": require("../../assets/cancel.png"),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" Minimum 8 Characters ")]) : _vm._e(), _c('div', {
    staticClass: "flex gap-2 item"
  }, [_vm.isConfirm ? _c('img', {
    attrs: {
      "src": require("../../assets/accept.png"),
      "alt": ""
    }
  }) : _vm._e(), !_vm.isConfirm ? _c('img', {
    attrs: {
      "src": require("../../assets/cancel.png"),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" Two passwords have to be a same ")])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }