<template>
  <div>
    <v-select v-if="!isAll" label="Select Tenant" v-model="selectedTenant" outlined :items="tempTenant" item-text="name"
      item-value="id">


      <template v-slot:selection="{ item }">
        <v-avatar width="32" height="32" tile>
          <img style="margin: 5px" :src="'https://assets.trainplanet.com/wlabel/' +
            fixUrl(item.name) +
            '/logos/favicon-32x32.png'
            " />
        </v-avatar>
        {{ item.name }}
      </template>
      <template v-slot:item="{ item }">
        <v-avatar width="32" height="32" tile>
          <img style="margin: 5px" :src="'https://assets.trainplanet.com/wlabel/' +
            fixUrl(item.name) +
            '/logos/favicon-32x32.png'
            " />
        </v-avatar>
        {{ item.name }}
      </template>

      <template v-slot:append-outer>
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          <span>Choose tenant to create the right theme.</span>
        </v-tooltip>
      </template>
    </v-select>



    <v-select v-if="isAll" label="Select Tenant" v-model="selectedTenant" outlined :items="allTenantsMap" item-text="name"
      item-value="id">


      <template v-slot:selection="{ item }">
        <v-avatar width="32" height="32" tile>
          <img style="margin: 5px" :src="'https://assets.trainplanet.com/wlabel/' +
            fixUrl(item.name) +
            '/logos/favicon-32x32.png'
            " />
        </v-avatar>
        {{ item.name }}
      </template>
      <template v-slot:item="{ item }">
        <v-avatar width="32" height="32" tile>
          <img style="margin: 5px" :src="'https://assets.trainplanet.com/wlabel/' +
            fixUrl(item.name) +
            '/logos/favicon-32x32.png'
            " />
        </v-avatar>
        {{ item.name }}
      </template>

      <template v-slot:append-outer>
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          <span>Choose tenant to create the right theme.</span>
        </v-tooltip>
      </template>
    </v-select>

  </div>
</template>

<script>
import { fixUrl } from "@/util/helpers";
import { mapGetters } from "vuex";

export default {
  name: "SelectTenant",
  props: {
    tenants: [],
    allTenants: [],
    isAll: false

  },
  emit: ['onSelect'],
  mounted() {

    if (this.tempTenant.length == 0) {
      this.tempTenant = this.tenants
      this.tenantFilter()
    }
  },
  created() {
    if (this.allTenantsMap.length > 0) {
      this.selectedTenant = this.allTenantsMap[0]

    }
  },
  data() {
    return {
      selectedTenant: "",
      fab: false,
      tempTenant: [],
    };
  },
  computed: {
    ...mapGetters({
      allTenantsMap: "auth/userTenants",
      userRoles: "auth/userRoles",
      user: "auth/user",
    }),

  },
  methods: {
    fixUrl,
    tenantFilter() {
      var tenantArray = []
      this.tempTenant.forEach(element => {
        if (this.allTenants.filter(e => e.id === element).length > 0) {
          var index = this.allTenants.findIndex(e => e.id == element)
          tenantArray.push(this.allTenants[index])
        }
      });
      this.tempTenant = tenantArray
      this.selectedTenant = this.tempTenant[0]

    },
    onSelectTenant() {
      this.$emit('onSelect', this.selectedTenant)
    }
  },
  watch: {

    'allTenants': {
      handler: function f(val) {
        this.tempTenant = this.tenants
        this.tenantFilter()
      }
    },
    'allTenantsMap': {
      handler: function f(val) {
        this.selectedTenant = this.allTenantsMap[0]
      }
    }
    ,
    'selectedTenant': {
      deep: true,
      handler: function f(val) {
        this.onSelectTenant();
      }
    }
  }
}
</script>
