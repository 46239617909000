var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "py-8 bg-white rounded-lg bx-shadow mt-3"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.step === 1,
      expression: "step === 1"
    }],
    staticClass: "flex justify-center flex-col items-center register min-500",
    staticStyle: {
      "min-width": "200px"
    }
  }, [_c('h2', {
    staticClass: "text-black text-center font-medium text-2xl",
    staticStyle: {
      "margin-bottom": "3rem"
    }
  }, [_vm._v("Create Account")]), _c('v-form', {
    ref: "registerForm",
    staticClass: "grid grid-cols-1 flex text-start flex-wrap flex-row gap-4 w-100 justify-center items-center register-form",
    staticStyle: {
      "width": "100%"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.register.apply(null, arguments);
      }
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "clearable": "",
      "label": "First Name",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "firstName", $$v);
      },
      expression: "form.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "label": "Last Name",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lastName", $$v);
      },
      expression: "form.lastName"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "clearable": "",
      "label": "Email",
      "color": "orange"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "6",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('VuePhoneNumberInput', {
    staticStyle: {
      "height": "60px !important"
    },
    attrs: {
      "default-country-code": _vm.selectedCountryCode,
      "error": !_vm.phoneValid,
      "clearable": true,
      "countries-height": 50
    },
    on: {
      "update": _vm.logPhone
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "height": "56px",
      "width": "100%"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.uuidv4
    }
  }, [_vm._v("Generate Password")])], 1), _c('v-col', {
    staticClass: "pb-0",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "rules": [_vm.rules.required],
      "append-icon": _vm.passEye ? 'mdi-eye' : 'mdi-eye-off',
      "outlined": "",
      "clearable": "",
      "label": "Password",
      "type": _vm.passEye ? 'text' : 'password',
      "hide-details": "",
      "color": "orange"
    },
    on: {
      "click:append": function ($event) {
        _vm.passEye = !_vm.passEye;
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c('password-control', {
    attrs: {
      "ifCondition": this.form.password && this.form.password != '',
      "password": this.form.password,
      "passwordConfirm": _vm.passwordConfirm
    },
    on: {
      "done": function ($event) {
        _vm.isDone = $event;
      }
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "rules": [_vm.rules.required],
      "hide-details": "",
      "type": _vm.passEye ? 'text' : 'password',
      "append-icon": _vm.passEye ? 'mdi-eye' : 'mdi-eye-off',
      "outlined": "",
      "clearable": "",
      "label": "Password Confirm",
      "color": "orange"
    },
    on: {
      "click:append": function ($event) {
        _vm.passEye = !_vm.passEye;
      }
    },
    model: {
      value: _vm.passwordConfirm,
      callback: function ($$v) {
        _vm.passwordConfirm = $$v;
      },
      expression: "passwordConfirm"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }), _c('v-col', {
    staticClass: "register-tenant",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('SelectTenant', {
    attrs: {
      "isAll": true,
      "tenants": _vm.allTenants,
      "allTenants": _vm.listedTenants
    },
    on: {
      "onSelect": function ($event) {
        return _vm.selectTenant($event);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }), _c('v-col', {
    staticClass: "pb-0",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }), _c('v-col', {
    staticClass: "pb-0",
    staticStyle: {
      "height": "40px",
      "justify-content": "center",
      "display": "flex"
    },
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "height": "56px",
      "width": "100%",
      "font-size": "14px"
    },
    attrs: {
      "dark": "",
      "small": "",
      "color": "accent"
    },
    on: {
      "click": _vm.register
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }]
  }, [_vm._v(" Create Account ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mng-loader"
  })])], 1)], 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }